import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/dfa-theme/layout';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ContentBlock from '../components/content-block';
import { CONSTANTS } from '../utils/constants';
import { createClient } from '@sanity/client';
import fragilexPdfImage from '../images/fragilex-pdf.png'; // Import the image
import '../scss/pages/harmony.scss';

const client = createClient({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_PROJECT_DATASET,
  apiVersion: '2024-10-31',
  useCdn: true
});

const HarmonyPage = () => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const query = `*[_type == "harmonyPdf"][0] {
          "pdfRef": pdfFile.asset->url,
        }`;

        const result = await client.fetch(query);
        if (result?.pdfRef) {
          setPdfUrl(result.pdfRef);
        } else {
          setError('PDF not found');
        }
      } catch (error) {
        console.error('Error fetching PDF:', error);
        setError('Error loading PDF');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPdf();
  }, []);

  const openPdfInNewTab = () => {
    if (pdfUrl) {
      window.open(pdfUrl, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Harmony | Deerfield Agency</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Helmet>
      <div className="harmony-container block">
        <ContentBlock
          outerBgColor={CONSTANTS.WHITE}
          className="harmony-content block"
        >
          <Grid>
            <Row center="xs" middle="xs">
              <Col xs={1}></Col>
              <Col xs={10} className="text-center">
                {isLoading ? (
                  <div className="loading">Loading PDF...</div>
                ) : error ? (
                  <div className="error">{error}</div>
                ) : pdfUrl ? (
                  <div className="pdf-container">
                    <div className="desktop-view">
                      <iframe
                        key={pdfUrl}
                        src={`${pdfUrl}#pagemode=none&view=Fit&toolbar=0&navpanes=0`}
                        title="PDF Document"
                        className="pdf-iframe"
                        loading="lazy"
                        onError={(e) => setError('Failed to load PDF viewer')}
                      />
                    </div>
                    <div className="mobile-view">
                      <img
                        src={fragilexPdfImage}
                        alt="FragileX PDF Preview"
                        className="pdf-preview"
                        onClick={openPdfInNewTab}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <button className='dfa-button-orange pdf-button' onClick={openPdfInNewTab}>
                      <span className="desktop-text">open pdf in new tab</span>
                      <span className="mobile-text">click to view pdf</span>
                    </button>
                  </div>
                ) : (
                  <div className="error">PDF not found</div>
                )}
              </Col>
              <Col xs={1}></Col>
            </Row>
          </Grid>
        </ContentBlock>
      </div>
    </Layout>
  );
};

export default HarmonyPage;